import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from '../../models/index'

const initialState: Notification = {
    titleNotification: 'Ocurrio un error, intentelo mas tarde!',
    showNotification: false,
    type: 'error-container',
}

export const appSlice = createSlice({
    name: 'imgScreen',
    initialState: initialState,
    reducers: {
        setErrorPhonen: (state) => {
            state.titleNotification = 'Numero no registrado!';
            state.showNotification= true;
        },
        setErrorback: (state) => {
            state.titleNotification = 'Ocurrio un error, intentelo mas tarde!';
            state.showNotification= true;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.titleNotification = action.payload;
            state.showNotification= true;
        },
        deleteNotification: (state) => {
            state.titleNotification = '';
            state.showNotification= false;
        },
    }
});

export const { 
    setErrorPhonen,
    setErrorback,
    setError,
    deleteNotification
} = appSlice.actions;

export default appSlice.reducer;
