import {IonFab, IonFabButton, IonImg} from '@ionic/react';
import './Loader.scss';
import loader from '../../assets/loader.svg';

export const Loader: React.FC = () => {

  return (
    <IonFab vertical="center" horizontal="center" slot="fixed">
        <IonFabButton>
            <IonImg src={loader}></IonImg>
          </IonFabButton>
     </IonFab>
  );
};