import { createSlice } from "@reduxjs/toolkit";
import { dataScreen } from '../../models/index'
import mg_asesor_error from '../../assets/something_went_wrong.svg';
import mg_asesor_succes from '../../assets/img_asesor_check.svg';

const initialState: dataScreen = {
    img: '',
    title: '',
    subTitle: '' 
}

export const userSlice = createSlice({
    name: 'imgScreen',
    initialState: initialState,
    reducers: {
        setErrorScreen: (state) => {
            state.img = mg_asesor_error;
            state.title = '¡Algo salió mal!';
            state.subTitle = 'Vuelve a intentar el proceso';
        },
        setSuccesScreen: (state) => {
            state.img = mg_asesor_succes;
            state.title = '¡Consulta exitosa!';
            state.subTitle = 'Tu asesor te contactará para continuar';
        },
        clearResults: (state) => {
            state.img = '';
            state.title = '';
            state.subTitle = '';
        },
    }
});

export const { 
    setErrorScreen,
    setSuccesScreen,
    clearResults
} = userSlice.actions;

export default userSlice.reducer;
