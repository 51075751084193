import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from '../../models/index'

const initialState: User = {
    cellphone: '',
    sessionId: '',
    widgetDicio: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setCellphone: (state, action: PayloadAction<string>) => {
            state.cellphone = action.payload;
        },
        setSessionId: (state, action: PayloadAction<string>) => {
            state.sessionId = action.payload;
        },
        setWidgetDicio: (state, action: PayloadAction<string>) => {
            state.widgetDicio = action.payload;
        },
        clearResults: (state) => {
            state.cellphone = '';
            state.sessionId = '';
            state.widgetDicio = '';
        },
    }
});

export const { 
    setCellphone,
    setSessionId,
    setWidgetDicio,
    clearResults
} = userSlice.actions;

export default userSlice.reducer;
