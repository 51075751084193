import './Start.scss';
import { CreateAnimation, IonImg, IonItem, 
    IonLabel, IonPage, useIonRouter, useIonLoading} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import Logo from '../../assets/eliiot-logo.svg';
import smartPhoneIcn from '../../assets/icn-smartphone.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setCellphone, setWidgetDicio, setSessionId } from '../../store/reducers/userSlice';
import {RootState} from '../../store/store';
import{fixiApi} from '../../api/fixiApi'
import { Message } from '../../components/Notification/Message';
import { setError, setErrorback } from '../../store/reducers/appSlice';

const Start: React.FC = () => {

    const cellphone = useSelector((state: RootState)  => state.user.cellphone)
    const appData = useSelector((state: RootState)  => state.app)

    const dispatch = useDispatch();
    const router = useIonRouter();
    const [present, dismiss] = useIonLoading();

    const keyframesAnm = [
        { offset: 0, transform: 'scale(1)', opacity: '1' },
        { offset: 0.5, transform: 'scale(0.8)', opacity: '0.5' },
        { offset: 1, transform: 'scale(1)', opacity: '1' }
    ];
    
    const [cellphoneField, setcellphoneField] = useState( {
        displayError: "none",
        diableBtn: true
    });

    const validatecellphone = (e: any) => {
        if(e.target.value.length <= 10){
            dispatch(setCellphone(String(e.target.value)))
            if(!/^[0-9]\d{9}$/.test(e.target.value)){
                setcellphoneField({
                    displayError: "block",
                    diableBtn: true
                })
            } else {
                setcellphoneField({
                    displayError: "none",
                    diableBtn: false
                })
            }
        }
    }

   useEffect(() => {
        dispatch(setCellphone(String(cellphone.replace("+52", ""))))
    })

    const navigateDicio = ()  => {
        router.push("/dicio", "forward", "push");
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
       present({
            animated: true,
            translucent: true,
            keyboardClose: true,
            cssClass: 'custom-loading',
            mode: 'ios'
          })
        fixiApi.post('dicio/getWidget',{cellphone: String(`+52${cellphone}`)})
        .then(response => {
            try{
                if(response.data){
                    const {data} = response.data
                    if(data.start){
                       const {widget} = data
                       if(widget) {
                        console.log(widget)
                            const urlParams = new URLSearchParams(widget)
                            var sessionId = urlParams.get('uuidTrx');
                            dispatch(setWidgetDicio(String(widget + 'https://3c5b-177-242-194-155.ngrok.io')))
                            dispatch(setSessionId(String(sessionId)))
                            navigateDicio()
                       } else {
                        dispatch(setErrorback())
                       }
                    } else {
                        dispatch(setErrorback())
                    }
                } else {
                    dispatch(setErrorback())
                }
                dismiss()
            } catch (error){
                dispatch(setErrorback())
                dismiss()
            }
        }).catch(error => {
            dispatch(setError(error.response.data.message))
            dismiss()
        })
        
    }
    
    const renderNotification = () => {
        if (appData.showNotification) {
            return <div className="notification"><Message/> </div>
        } else {
            return <></>
        }
    }

    return(
        <IonPage>
            { renderNotification() }
            <section className="start">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="input-group">
                    <CreateAnimation
                        duration={1500}
                        keyframes={keyframesAnm}
                        play={true}
                        >
                        <IonImg src={Logo} alt="logo"/>
                    </CreateAnimation>
                        <h2>Ingresa tu celular para <br /> iniciar tu solicitud</h2>
                        <div className="start-input-group">
                            <img src={smartPhoneIcn} className="phone-icn" alt="logo" />
                            <input 
                            className="first-phone"
                            type="tel"
                            placeholder="a 10 dígitos"
                            onChange={(e) => {validatecellphone(e)}}
                            onBlur={(e) => {validatecellphone(e)}}
                            value={String(cellphone)}
                            />
                        </div>
                        <p className="error-text" style={{display: cellphoneField.displayError}}>Ingresa un número válido</p>
                    </div>
                    <div className='btn-group' >
                         <IonItem color="transparent" lines="none">
                            <IonLabel >
                                Realizalo tu proceso con calma 😌, <br />  tendrás <b>30 minutos</b> para completarlo.
                            </IonLabel>
                         </IonItem>
                         <button  type="submit" disabled={cellphoneField.diableBtn} >Continuar</button>
                    </div>
                </form>
            </section>
        </IonPage>
    );
  };
  
  export default Start;
  