
import Icnx from '../../assets/icn-x-white.svg';
import './messages.scss';
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../store/store';
import { deleteNotification } from '../../store/reducers/appSlice';

export const Message: React.FC = ()=> {
    const dispatch = useDispatch();
    const appData = useSelector((state: RootState)  => state.app)

    return (
        <section>
            <div className={appData.type}>
                <p>{ appData.titleNotification }</p>
                <img src={Icnx} alt="cerrar" onClick={() => {dispatch(deleteNotification())}} />
            </div>
        </section>
    );
};
