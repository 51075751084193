import './Dicio.scss';
import React, {useEffect ,useState} from 'react';
import {IonPage, useIonRouter, useIonViewDidEnter} from '@ionic/react';
import {useSelector, useDispatch } from 'react-redux';
import {RootState} from '../../store/store';
import { setSuccesScreen } from '../../store/reducers/imgSlice';
import { useTime } from 'react-timer-hook';
import{fixiApi} from '../../api/fixiApi';
import { Step } from '../../models';

const Dicio: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState)  => state.user)

  const [firsDate] = useState<Date>(new Date());
  const [secondDate, setSecondDate] = useState<Date>(new Date());
  const [createSecDAT, setcreateSecDAT] = useState<boolean>(false);

  const {
    seconds,
    minutes
  } = useTime({ format: '12-hour'});

  const widgetDc = 
<iframe
      src={String(user.widgetDicio)}
      title='widgetDicio'
      loading = 'eager'
      width="100%"
      height="100%"
      frameBorder="0"
      marginHeight={0}
      marginWidth={0}
      className="iframeV"
      allow="camera *; microphone *; autoplay; clipboard-write; encrypted-media; picture-in-picture full"
    /> 

  const navigateConsulta = () => {
    router.push("/consulta", "forward", "push");
  }

  useIonViewDidEnter(() => {
    console.log('DICIOWIDGET', user.widgetDicio)
    dispatch(setSuccesScreen())
  });

  useEffect(() => {
    //firsDate.setMinutes(firsDate.getMinutes() + 1)
    if(true){
      if(!createSecDAT) {
        console.log(new Date())
        const fecha = new Date();
        fecha.setSeconds(secondDate.getSeconds() + 30)
        setSecondDate(fecha);
        setcreateSecDAT(true)
      }
      console.log('second: ' + seconds + ' sumSefundoCount: ' + secondDate.getSeconds() + 'create dat: '+ createSecDAT)
      if (seconds === secondDate.getSeconds() && createSecDAT){
        fixiApi.get('dicio/status?sessionId=' + user.sessionId)
        .then(response => {
            try{
                if(response.data){
                    const {data} = response.data
                    if(data.steps){
                      console.log(data.steps)
                      const stepDcio: Step = data.steps.find((step: Step) => step.name  === 'finished');
                      if(stepDcio) {
                        navigateConsulta()
                      } else {
                        console.log('AUN NO TERMINA EL FLUJO ')
                      }
                    }
                } 
            } catch (error){
                
            }
        })
        setcreateSecDAT(false)
      }
    }
  },  [minutes, firsDate, createSecDAT, seconds, secondDate, user.sessionId, navigateConsulta] )

/*
  const responseWd = (event: any) => {
    console.log(event)
  }

  useEffect(() => {
    window.addEventListener("message", responseWd); 
    return () => window.removeEventListener("message", responseWd);
  }, [responseWd]);
*/
    return(
      
        <IonPage>
            {widgetDc}
            {/*<IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton onClick={navigateConsulta}>
                <IonIcon icon={check}/>
              </IonFabButton>
            </IonFab>*/}
        </IonPage>
    );
  };
  
  export default Dicio;