import './ConsultaScreen.scss';
import React, { useState } from 'react';
import { IonPage, IonImg, IonList, IonItem, IonLabel, CreateAnimation, useIonViewDidEnter} from '@ionic/react';
import { Loader } from '../../components/Loader/Loader';
import {useSelector } from 'react-redux';
import {RootState} from '../../store/store';

export const ConsultaScreen: React.FC = () => {
  
    const data = useSelector((state: RootState)  => state.imgScreen)

    const [loading, setLoading] = useState(true);
    const keyframesAnm = [
        { offset: 0, transform: 'scale(1)', opacity: '1' },
        { offset: 0.5, transform: 'scale(0.9)', opacity: '0.8' },
        { offset: 1, transform: 'scale(1)', opacity: '1' }
    ];

    useIonViewDidEnter(() => {
        setTimeout(() => {
            console.log('list');
            setLoading(false)
        }, 4000)
    });

  return (
    <IonPage>
        { loading ?
            <Loader /> : 
            <section className="successScreen">
            <div className="dataAsesor">
                <CreateAnimation
                    duration={2500}
                    keyframes={keyframesAnm}
                    iterations={Infinity}
                    play={true}
                >
                        <IonImg src={data.img} alt="logo"/>
                </CreateAnimation>
                <br />
                <div className="subTitle">
                    <IonList>
                        <IonItem lines='none' color='transparent'>
                            <IonLabel>
                                <h2>{data.title}</h2>
                                <p>{data.subTitle}</p>
                            </IonLabel>
                        </IonItem>
                        <IonItem lines='none' color='none' >
                            <div className='rectangle'>
                            <IonLabel>
                                <p>Guarda tu folio de seguimiento.</p>
                                <h3>Folio: 12345</h3>
                            </IonLabel>
                            </div>
                        </IonItem>
                    </IonList>
                </div>    
            </div>
        </section>
        }
    </IonPage>
  );
};