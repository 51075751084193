import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Splash/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import Start from './pages/Start/Start';
import { ConsultaScreen } from './pages/ConsultaScreen/ConsultaScreen';
import Dicio from './pages/Dicio/Dicio';
import {useSelector } from 'react-redux';
import {RootState} from './store/store';

setupIonicReact();
const App: React.FC = () => {
  const widgetDicio = useSelector((state: RootState)  => state.user.widgetDicio)

  return (
    <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home" component={Home}/>
        <Route exact path="/start" component={Start}/>
        <Route exact path="/consulta" render={() =>  widgetDicio ? <ConsultaScreen/> : <Redirect to="/home" />}/>
        <Route exact path="/dicio" render={() =>  widgetDicio ? <Dicio/> : <Redirect to="/home" />}/>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  );
  };

export default App;
