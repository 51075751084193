import {configureStore } from "@reduxjs/toolkit";

//Reducers
import userReducer from './reducers/userSlice';
import imgScreen from './reducers/imgSlice';
import appSlice from "./reducers/appSlice";

export const store = configureStore({
    reducer: {
        user : userReducer,
        imgScreen: imgScreen,
        app: appSlice
    },
  })
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch